var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"glacier-keycap-set px",class:{ mobile: _vm.isMobile, cn: _vm.isCN }},[_c('v-header',{attrs:{"title":_vm.$t('emergenceKeycaps.name'),"name":"EMERGENCE KEYCAPS"}}),_c('img',{staticClass:"image01",attrs:{"src":_vm.isMobile
        ? _vm.isCN
          ? _vm.oss.emergence_keycaps_01_cn_mobile
          : _vm.oss.emergence_keycaps_01_en_mobile
        : _vm.isCN
        ? _vm.oss.emergence_keycaps_01_cn
        : _vm.oss.emergence_keycaps_01_en,"alt":"EMERGENCE KEYCAPS"}}),_c('div',{staticClass:"intro",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.intro'))}}),_c('img',{staticClass:"line",attrs:{"src":_vm.oss.glacier_keycap_set_line,"alt":"EMERGENCE KEYCAPS"}}),_c('div',{staticClass:"title01",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.title01'))}}),_c('div',{staticClass:"text01",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.text01'))}}),_c('img',{staticClass:"image02",attrs:{"src":_vm.oss.emergence_keycaps_02,"alt":"EMERGENCE KEYCAPS"}}),_c('div',{staticClass:"text02",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.text02'))}}),_c('img',{staticClass:"image03",attrs:{"src":_vm.oss.emergence_keycaps_03,"alt":"EMERGENCE KEYCAPS"}}),_c('div',{staticClass:"title02",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.title02'))}}),_c('div',{staticClass:"text03",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.text03'))}}),_c('img',{staticClass:"image04",attrs:{"src":_vm.oss.emergence_keycaps_04,"alt":"EMERGENCE KEYCAPS"}}),_c('div',{staticClass:"title03",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.title03'))}}),_c('div',{staticClass:"text04",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.text04'))}}),_c('div',{staticClass:"tips",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.tips'))}}),_c('img',{staticClass:"image05",attrs:{"src":_vm.oss.emergence_keycaps_05,"alt":"EMERGENCE KEYCAPS"}}),_c('div',{staticClass:"title04",domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.title04'))}}),_vm._l((1),function(i){return _c('div',{key:'swiper-box' + i,staticClass:"swiper-box"},[_c('swiper',{staticClass:"swiper",attrs:{"options":{
        navigation: {
          prevEl: '.prev' + i,
          nextEl: '.next' + i
        }
      }}},_vm._l((6),function(j){return _c('swiper-slide',{key:'swiper-slide' + j,staticClass:"swiper-slide"},[_c('img',{staticStyle:{"width":"100vw"},attrs:{"src":_vm.oss[("emergence_keycaps_swiper_0" + i + "_0" + j)],"alt":"CYBERBOARD TERMINAL"}})])}),1),_c('div',{staticClass:"swiper-button-prev",class:'prev' + i,attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",class:'next' + i,attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"image-text",staticStyle:{"margin":"0.2rem 0 1.5rem 0"},domProps:{"innerHTML":_vm._s(_vm.$t('emergenceKeycaps.swiperName0' + i))}})],1)}),_c('v-join-us',{attrs:{"src":_vm.oss.qr_code_wechat_club}}),_c('v-footer')],2)}
var staticRenderFns = []

export { render, staticRenderFns }